import { useEffect, useState } from "react";

interface AdobeTargetOfferParams {
  mbox: string;
  flagName: string;
  params: object;
}

type OfferValue = boolean | number | string | undefined;

// Custom hook to fetch the offer and extract the passed flag
export const useAdobeTargetOffer = ({
  mbox,
  flagName,
  params,
}: AdobeTargetOfferParams) => {
  const [value, setValue] = useState<OfferValue>(undefined);
  const [hasResolved, setHasResolved] = useState(false);

  useEffect(() => {
    if (hasResolved) return;

    const fetchOffer = async () => {
      if (typeof window.adobe?.target?.getOffer !== "function") {
        console.error("Adobe Target is not available.");
        setValue(undefined);
        setHasResolved(true);
        return;
      }

      try {
        const response = await new Promise<
          Array<{
            content: Array<{
              [key: string]: OfferValue;
            }>;
          }>
        >((resolve, reject) => {
          const timeout = setTimeout(
            () => reject(new Error("Request timed out")),
            2000
          ); // 2 seconds timeout

          // @ts-expect-error Complains for no reason as there is a check above that prevents an undefined.
          window.adobe.target.getOffer({
            mbox,
            params,
            success: (res: { content: { [key: string]: OfferValue }[] }[]) => {
              clearTimeout(timeout);
              resolve(res);
            },
            error: (err) => {
              clearTimeout(timeout);
              reject(err);
            },
          });
        });

        const content = response?.[0]?.content?.[0];
        const flagValue = content?.[flagName];

        if (["boolean", "number", "string"].includes(typeof flagValue)) {
          setValue(flagValue as OfferValue);
        } else {
          console.warn(
            `Unexpected type for flag "${flagName}":`,
            typeof flagValue
          );
          setValue(undefined); // Fallback if type is unexpected
        }
      } catch (error) {
        console.error("Failed to fetch Adobe Target offer", error);
        setValue(undefined);
      } finally {
        setHasResolved(true);
      }
    };

    fetchOffer();
  }, [mbox, flagName, params, hasResolved]);

  return [value, hasResolved] as const;
};
