import React from "react";

import { useEvaluationData } from "../lib/useEvaluationData";
import { EvaluationStatus } from "../lib/types/Evaluation.types";
import {
  ExperimentGroup,
  TExperimentGroup,
  TOfferStatus,
  TSubmissionData,
  handleApiError,
  isOfferStatusTransferFailed,
  trackApiError,
} from "../lib";
import LanguageBannerWebShop from "./LanguageBannerWebShop";
import LanguageBannerAI from "./LanguageBannerAI";
import DownloadManuscript from "../EvaluateView/DownloadManuscript";
import { useAdobeTargetOffer } from "../lib/useAdobeTargetOffer/useAdobeTargetOffer";

const LanguageBannerRouter = ({
  languageExperiment,
  offerStatus,
  pubDNumber,
  position,
  submissionUuid,
}: {
  languageExperiment: TExperimentGroup;
  offerStatus: TOfferStatus;
  pubDNumber: TSubmissionData["pubDNumber"];
  position: "before-main-heading" | "after-main-heading";
  submissionUuid: string;
}) => {
  const { data, error } = useEvaluationData({
    languageExperiment,
    offerStatus,
    submissionUuid,
  });

  const evaluationData = data?.data;

  const [bannerVersion] = useAdobeTargetOffer({
    mbox: "AH_LANGUAGE_BANNER",
    flagName: "BANNER_VERSION",
    params: {
      languageAIExperiment: languageExperiment === ExperimentGroup.EXPERIMENT,
    },
  });
  if (languageExperiment !== ExperimentGroup.EXPERIMENT) {
    return <LanguageBannerWebShop position={position} />;
  }

  if (
    evaluationData &&
    evaluationData.status === EvaluationStatus.NOT_STARTED &&
    !isOfferStatusTransferFailed(offerStatus)
  ) {
    return (
      <>
        <LanguageBannerAI
          position={position}
          submissionUuid={submissionUuid}
          version={
            bannerVersion !== undefined ? (bannerVersion as 1 | 2 | 3) : 1
          }
        />
      </>
    );
  } else if (
    evaluationData &&
    evaluationData.status === EvaluationStatus.SUCCEEDED
  ) {
    return (
      <DownloadManuscript
        evaluationStatus={evaluationData.status}
        pubDNumber={pubDNumber}
        submissionUuid={submissionUuid}
      />
    );
  } else {
    if (error) {
      const handledApiError = handleApiError(error);
      trackApiError({
        action: "fetch_evaluation_status",
        status: handledApiError.errorDetails || "unknown",
      });
    }

    // Covers loading and error states
    return null;
  }
};

export default LanguageBannerRouter;
