import React, { forwardRef } from "react";

import {
  isOfferStatusTransferSucceeded,
  AppErrorType,
  TSubmissionData,
  EvaluationStatus,
} from "../lib";
import { BetaBadge, Box, UtilBar } from "../generic";

import LoginToJournalLink from "../StatusView/LoginToJournalLink";
import SubmissionInstructions from "../StatusView/SubmissionInstructions";
import ViewContainer from "../ViewContainer";

import BackToTransferStatusLink from "./BackToTransferStatusLink";
import DownloadManuscript from "./DownloadManuscript";
import * as css from "./EvalutateView.css";
import { TBoxColor } from "../generic/Box/Box.css";
import { TEvaluationData } from "../lib";
import ErrorView from "../ErrorView";

const EvaluateSucceeded = forwardRef(
  (
    {
      score,
      submissionData,
    }: {
      score: TEvaluationData["score"];
      submissionData: TSubmissionData;
    },
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const { transfer } = submissionData;

    if (typeof score !== "number") {
      return (
        <ErrorView
          errorType={AppErrorType.API_ERROR}
          submissionData={submissionData}
        />
      );
    }

    const getScoreInfo: (score: number) => {
      style: TBoxColor;
      message: string;
    } = (score) => {
      if (score > 0.8) {
        return {
          style: "success",
          message: "Very minor chance of rejection due to language.",
        };
      } else if (score >= 0.6 && score <= 0.8) {
        return {
          style: "warning",
          message: "Moderate chance of rejection due to language.",
        };
      } else {
        return {
          style: "alert",
          message: "Strong chance of rejection due to language.",
        };
      }
    };

    const scoreInfo = getScoreInfo(score);

    return (
      <ViewContainer
        viewName="evaluate:succeeded"
        htmlTitle={`Manuscript analysis complete for ${submissionData.title}`}
        topTitle="Improve your manuscript for"
        submissionData={submissionData}
      >
        <div css={[css._evaluateGridCSS, css._resultColumnsCSS]}>
          <Box className="main-column">
            <BetaBadge id="is-beta" />
            <h1
              aria-describedby="is-beta"
              css={css._evaluateH1Size}
              data-testid="evaluate-title"
              ref={ref}
              tabIndex={-1}
            >
              <strong>Analysis complete</strong>
            </h1>
            <p>
              We have analysed your manuscript and suggested some changes to
              increase your chances of publication. You can download your
              amended manuscript and use it to replace the existing one when you
              edit your submission on your chosen journal&apos;s website.
            </p>
          </Box>
          <Box
            className="side-column"
            color={scoreInfo?.style}
            variant="background"
          >
            <h2 css={css._evaluateH2Size}>
              <strong>Language score: {Math.round(score * 100)}%</strong>
            </h2>
            <p>{scoreInfo?.message}</p>
          </Box>
        </div>
        <DownloadManuscript
          evaluationStatus={EvaluationStatus.SUCCEEDED}
          pubDNumber={submissionData.pubDNumber}
          submissionUuid={submissionData.submissionUuid}
        />
        <Box>
          <UtilBar>
            {isOfferStatusTransferSucceeded(submissionData.status) &&
              transfer && (
                <LoginToJournalLink
                  analyticsRegion="ah-evaluate"
                  targetJournal={transfer.targetJournal}
                  variant="button"
                />
              )}
            <BackToTransferStatusLink />
          </UtilBar>
        </Box>
        {isOfferStatusTransferSucceeded(submissionData.status) && (
          <SubmissionInstructions
            analyticsRegion="ah-evaluate"
            targetJournal={transfer?.targetJournal}
          />
        )}
      </ViewContainer>
    );
  }
);

export default EvaluateSucceeded;
