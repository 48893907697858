import React, { useEffect, useState } from "react";
import * as lib from "../lib";

import { Box, Button, ExternalLink } from "../generic";
import {
  _downloadColumnsCSS,
  _evaluateH3Size,
  _evaluateGridCSS,
  _evaluateH2Size,
} from "./EvalutateView.css";
import { useEvaluationDownload } from "../lib/useEvaluationDownload/useEvaluationDownload";
import { _srOnlyCSS, TEvaluationStatus, TSubmissionData } from "../lib";

const DownloadManuscript = ({
  evaluationStatus,
  pubDNumber,
  submissionUuid,
}: {
  evaluationStatus: TEvaluationStatus;
  pubDNumber: TSubmissionData["pubDNumber"];
  submissionUuid: string;
}) => {
  const [shouldFetchDownloadUrl, setShouldFetchDownloadUrl] = useState(false);
  const { data, isLoading } = useEvaluationDownload({
    submissionUuid,
    evaluationStatus,
    shouldFetchDownloadUrl,
  });

  const downloadUrl = data?.data.url;
  const onDownloadManuscriptButtonClick = () => {
    lib.trackFormButtonClick("downloadManuscript");

    setShouldFetchDownloadUrl(true);
  };
  useEffect(() => {
    const downloadFile = async () => {
      if (!downloadUrl) return;

      try {
        const response = await fetch(downloadUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch the file");
        }
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `amended-manuscript-${pubDNumber}.txt`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Download failed:", error);
      } finally {
        setShouldFetchDownloadUrl(false);
      }
    };

    if (shouldFetchDownloadUrl) {
      (async () => {
        await downloadFile();
      })();
    }
  }, [shouldFetchDownloadUrl, downloadUrl]);
  return (
    <Box
      color="info"
      variant="background"
      forwardCSS={[_evaluateGridCSS, _downloadColumnsCSS]}
    >
      <div className="main-column">
        <h2 css={_evaluateH2Size}>
          <strong>Download your amended manuscript</strong>
        </h2>
        <p css={_evaluateH3Size}>
          Check the applied language improvement to ensure your work is ready
          for submission.
        </p>
        <p>
          Note: you will need to add a statement to your manuscript declaring
          that generative AI has been used to improve readability and language.{" "}
          <ExternalLink
            analyticsRegion="ah-evaluate"
            analyticsName="ah-goto-about-gen-ai-declaration"
            href="https://www.elsevier.com/about/policies-and-standards/generative-ai-policies-for-journals"
            testId="about-gen-ai-declaration-link"
            styleType="text-underlined"
          >
            Read more about the declaration{" "}
            <span css={_srOnlyCSS}>regarding generative AI</span>
          </ExternalLink>
        </p>
      </div>
      <div className="side-column">
        <Button
          icon={{ name: "download", position: "after" }}
          styleType="primary"
          testId="download-manuscript-button"
          onClick={onDownloadManuscriptButtonClick}
          disabled={isLoading}
        >
          {isLoading ? "Preparing file..." : "Download manuscript"}
        </Button>
      </div>
    </Box>
  );
};

export default DownloadManuscript;
