import React from "react";

import { BetaBadge, Box, Icon, InternalLink } from "../generic";

import * as css from "./LanguageBanner.css";
import { _evaluateH2Size } from "../EvaluateView/EvalutateView.css";

const LanguageBannerAI = ({
  position,
  submissionUuid,
  version,
}: {
  position: "before-main-heading" | "after-main-heading";
  submissionUuid: string;
  version: 1 | 2 | 3;
}) => {
  const Title = position === "after-main-heading" ? "h2" : "p";

  const titleProps = {
    "aria-describedby": "language-banner-beta",
    css: _evaluateH2Size,
    "data-testid": "language-banner-title",
  };

  const languageBannerText = [
    {
      header: "Is your manuscript written in high quality English?",
      body: "In minutes, we can evaluate and suggest edits to the language and grammar that you have used.",
      cta: "Get your free evaluation",
    },
    {
      header:
        "Language Checker improves your chances of being published by improving your paper's English.",
      body: "In minutes, Language Checker uses AI to analyze your manuscript's English grammar and provide you with suggested edits – without affecting the accuracy of your scientific writing. All at no cost.",
      cta: "Start the Language Checker",
    },
    {
      header:
        "Language Checker improves your paper's English grammar but doesn't change your science.",
      body: "Using AI, Language Checker quickly suggests improvements to your manuscript's English – without affecting your scientific findings. You can easily check the suggestions and apply them to your manuscript.",
      cta: "Check your manuscript's English",
    },
  ];
  return (
    <section
      css={css._languageBannerAIContainer}
      data-testid="language-banner-experiment"
    >
      <Box forwardCSS={css._languageBannerBetaBox}>
        <BetaBadge id="language-banner-beta" />
      </Box>
      <Box
        color="promo"
        variant="background"
        forwardCSS={css._languageBannerPromoBox}
      >
        <div css={css._languageBannerAIGridCSS}>
          <div className="language-banner-icon">
            <Icon name="check-clipboard" />
          </div>
          <div className="language-banner-content">
            <Title {...titleProps}>
              <strong>{languageBannerText[version - 1].header}</strong>
            </Title>
            <p className="intro-text">{languageBannerText[version - 1].body}</p>
          </div>
          <div className="language-banner-link">
            <InternalLink
              analyticsRegion="ah-status"
              analyticsName="ah-goto-language-editing"
              icon={{ name: "navigate-right", position: "after" }}
              href={`/transfers/${submissionUuid}/evaluate`}
              styleType="primary"
              testId="language-editing-link"
            >
              {languageBannerText[version - 1].cta}
            </InternalLink>
          </div>
        </div>
      </Box>
    </section>
  );
};

export default LanguageBannerAI;
